import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import metricsScreenshotImageSrc from "images/Act.png";
import openedComment from "../images/OpenedComment.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import  { ReactComponent as PhotoIcon } from "feather-icons/dist/icons/image.svg";
import { ReactComponent as MessageIcon } from "feather-icons/dist/icons/message-square.svg";
import { ReactComponent as ClockIcon } from "feather-icons/dist/icons/clock.svg";
import { Element } from "react-scroll";
import  integrations from "../images/integrations.png";
import widgetGif from "../images/widgetGif.gif";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      {/* <Element name="features-section">
        <Features
          subheading={<Subheading>Features</Subheading>}
          heading={
            <>
              Experience Intelligent Discovery
            </>
          }
        />
      </Element> */}
      
      <MainFeature
        subheading={<Subheading>Feedback capture</Subheading>}
        heading="Capture feedback with screenshots, recordings and details"
        description="Stop struggling with vague and unclear client feedback, directly pinpoint what needs changing by automatically capturing screenshots, recordings, and key details."
        imageSrc={widgetGif}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
      />

      <MainFeature
        subheading={<Subheading>Feedback collaboration</Subheading>}
        heading="keep all your feedback in one place"
        description="Say goodbye to endless email threads. Keep track of client feedback and ensure everyone is on the same page with collaboration tools like comments, attachments, and status tracking."
        imageSrc={openedComment}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
      />

      <MainFeature
        subheading={<Subheading>2-way integrations</Subheading>}
        heading="Manage the feedback in your existing tools and workflows"
        description="The feedback is kept in sync with your existing tools, allowing your team to manage it all there. No need for extra logins or drastically change your workflow. Just clear feedback."
        imageSrc={integrations}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
      />

      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Without PowerFeedback</Subheading>}
        heading={
          <>
            Got vague <HighlightedText>"It doesn't work"</HighlightedText> feedback from your client? It sucks!
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={true}
        features={[
          {
            Icon: PhotoIcon,
            title: "No screenshot to explain the feebback",
            description: 'Just received a "fix this" from your client, but they left out the "what" and "where"?',
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: MessageIcon,
            title: "Feedback scattered across email threads",
            description: "Got lost in a long email thread yet again, just to understand your client's website feedback?",
            iconContainerCss: tw`bg-red-300 text-red-800`
          },
          {
            Icon: ClockIcon,
            title: "Wasting hours by manually creating tasks",
            description: "Copied and pasted feedback from an email into your project management tool, again?",
            iconContainerCss: tw`bg-purple-300 text-purple-800`
          }
        ]}
      />
      {/* <Element name="pricing-section">
        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          heading={
            <>
              Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
            </>
          }
          plans={[
            {
              name: "Essential",
              price: "$29.99",
              duration: "Monthly",
              mainFeature: "Analyze up to 5000 reviews a month",
              features: ["Sentiment analysis", "Keyword trends", "Feedback analysis across 2 platforms (e.g., Google Play and App Store)"],
            },
            {
              name: "Business",
              price: "$79.99",
              duration: "Monthly",
              mainFeature: "Analyze up to 20,000 reviews per month",
              features: ["Everything in Essential","Advanced insights with feature suggestions", "Priority Assistance"],
              featured: true
            },
            {
              name: "Enterprise",
              price: "Custom Pricing",
              duration: "Annual Only",
              mainFeature: "Unlimited reviews",
              features: ["Everything in Business","Tailored insights with custom reports", "Personal Assistance"]
            }
          ]}
        />
      </Element> */}
      
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      {/* <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "How long do you usually support an standalone template for ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Is there a subscribption service to get the latest templates ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Are the templates compatible with the React ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Do you really support Internet Explorer 11 ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
        ]}
      /> */}
      <GetStarted
        text="Tired of vague, unorganized feedback? Reach out now to get started."
      />
      <Footer />
    </AnimationRevealPage>
  );
}
