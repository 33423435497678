import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import config from './config';
import { Amplify } from 'aws-amplify'

console.log("Imported config:", config);

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "Api",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);