const config = {
    // Backend config
    apiGateway: {
      REGION: process.env.REACT_APP_REGION,
      URL: process.env.REACT_APP_API_URL,
    }
  };

  console.log("Region:", process.env.REACT_APP_REGION);
console.log("API URL:", process.env.REACT_APP_API_URL);
  
export default config;